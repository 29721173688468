/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useProfileTemplate } from 'hooks/profileTemplate';

import {
  Row,
  Col,
  Form,
  Button,
  Select,
  Switch,
  Tooltip,
  Input,
  Checkbox,
  Radio,
  Card,
  InputNumber,
} from 'antd';

import './styles.less';

const parts = [
  'RÉU',
  'REQDO / REQDA / REQUERIDO / REQUERIDA',
  'INDICIADO / INDICIADA',
  'APENADO / APENADA',
  'ACUSADO / ACUSADA',
  'DENUNCIADO / DENUNCIADA',
  'CONDENADO / CONDENADA',
  'REEDUCANDO / REEDUCANDA',
  'AUTOR DO FATO',
  'A FATO',
  'INFRATOR / INFRATORA',
  'REU',
  'POLO PASSIVO',
  'REPRESENTADO',
  'REQUERIDO A',
];

const sources = [
  'INTERPOL',
  'FBI',
  'OFAC',
  'UK',
  'EU',
  'UNSC',
  'CEAF',
  'CNEP',
  'MTE',
  'CA-SANCTIONS',
  'DDTC',
  'SEAPE-DF',
  'UNITED NATIONS SECURITY COUNCIL',
  'CONSELHO NACIONAL DE JUSTIÇA',
  'TRIBUNAL DE CONTAS DA UNIÃO',
  'BANK HINDERED SUPPLIERS',
  'IBAMA',
  'TRIBUNAL DE CONTAS DO ESTADO DE SÃO PAULO',
  'BANCO CENTRAL DO BRASIL',
  'COMISSAO DE VALORES MOBILIARIOS',
  'PORTAL DA TRANSPARÊNCIA',
];

const possibleParametersToCompare = ['CPF', 'Nome', 'Data de Nascimento'];

const I18N_BASE_PATH =
  'pages.private.profileModels.components.complianceRules.validationsFormSelector';
const I18N_REQUIRED_FIELDS = `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.requiredFields`;

const { Option } = Select;

const ComplianceRule = ({
  field,
  index,
  profileTemplate,
  formFieldName,
  disabled,
  actionWhenInvalidOptions,
  otherValidations,
  remove,
  hasCustomStatus,
  showSubjectTitle,
}) => {
  const { t: translate } = useTranslation();
  const { product } = useProfileTemplate();
  const [isCustomRule, setIsCustomRule] = useState(false);

  const ages = Object.keys(new Array(121).fill(null)).map(Number);

  otherValidations = otherValidations.filter(
    (item) =>
      item.rule !== 'has_no_criminal_background' &&
      profileTemplate?.backgroundCheckingServices?.pfAntecedentes === undefined
  );

  const ruleField = profileTemplate?.[formFieldName]?.[index];

  const rulesRequiredActionWhenPending = () =>
    [
      'parameters_equals_receita',
      'ocr_equals_parameters_data',
      'ocr_equals_receita',
    ].includes(profileTemplate?.[formFieldName]?.[index]?.rule?.key);

  const isProductRule = useMemo(() => {
    if (
      profileTemplate?.[formFieldName]?.[index]?.rule?.key === 'invalid_range_ages' ||
      [
        'score_check_below',
        'score_check_over',
        'score_check_between',
        'credit_score_below',
        'credit_score_over',
        'credit_score_between',
        'parameters_equals_receita',
        'ocr_equals_parameters_data',
        'ocr_equals_receita',
        'has_processes_keywords',
        'has_no_pep_department',
      ].includes(profileTemplate?.[formFieldName]?.[index]?.rule?.key)
    ) {
      setIsCustomRule(true);
      profileTemplate[formFieldName][index].switchCustom = true;
    } else {
      setIsCustomRule(false);
    }

    return (
      product?.template?.backgroundChecking?.validations?.find(
        (v) => v?.rule === ruleField?.rule?.key
      ) ||
      product?.template?.onboarding?.validations?.find(
        (v) => v?.rule === ruleField?.rule?.key
      )
    );
  }, [product, ruleField]);

  return (
    <>
      <Row
        key={field.key}
        className={classNames({
          'first-row': index === 0,
        })}
      >
        <Col span={12}>
          <Form.Item
            name={[field.name, 'rule']}
            fieldKey={[field.fieldKey, 'rule']}
            label={
              index === 0
                ? translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.rule.label`
                  )
                : undefined
            }
            rules={[{ required: true }]}
            className="mrg-btm-15"
          >
            <Select
              placeholder={translate(
                `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.rule.select.placeholder`
              )}
              showSearch
              optionFilterProp="label"
              labelInValue
              disabled={disabled}
              className="validation-title"
              showArrow={!disabled}
            >
              {otherValidations.map((v) => (
                <Option
                  key={v.rule}
                  value={v.rule}
                  label={
                    showSubjectTitle && v.subjectTitle
                      ? translate(`validations.${v.rule}.subjectTitle`, v.subjectTitle)
                      : translate(`validations.${v.rule}.title`, v.title)
                  }
                  disabled={v.disabled}
                >
                  <Tooltip
                    placement="left"
                    title={
                      translate(`validations.${v.rule}.details`, '') ||
                      translate(`validations.${v.rule}.description`, '') ||
                      translate(`validations.${v.rule}.title`, '')
                    }
                  >
                    <span className="width-100">
                      {showSubjectTitle && v.subjectTitle
                        ? translate(`validations.${v.rule}.subjectTitle`, v.subjectTitle)
                        : translate(`validations.${v.rule}.title`, v.title)}
                    </span>
                  </Tooltip>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name={[field.name, 'actionWhenInvalid']}
            fieldKey={[field.fieldKey, 'actionWhenInvalid']}
            label={
              index === 0
                ? translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.actionWhenInvalid.label`
                  )
                : undefined
            }
            rules={[{ required: true }]}
            className={profileTemplate?.[formFieldName]?.[index]?.actionWhenInvalid}
          >
            <Select
              placeholder={translate(
                `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.actionWhenInvalid.select.placeholder`
              )}
              disabled={disabled}
              dropdownClassName="validations-action-select-dropdown"
              allowClear
            >
              {actionWhenInvalidOptions(
                profileTemplate?.[formFieldName]?.[index]?.rule?.key
              ).map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item
            name={[field.name, 'switchCustom']}
            fieldKey={[field.fieldKey, 'switchCustom']}
            label={index === 0 ? 'Customizar' : undefined}
            valuePropName="checked"
            className="mrg-btm-15"
            hidden={isCustomRule}
          >
            <Switch
              disabled={disabled || isCustomRule}
              className="switch-compliance-rules"
            />
          </Form.Item>
        </Col>

        {!disabled && (
          <Col span={2} className="flex center-start last-column">
            <Form.Item>
              <Button
                htmlType="button"
                type="danger"
                rounded={false}
                onClick={() => remove(field.name)}
                className="remove-button"
                disabled={disabled}
              >
                <i className="caf-ic_trash" />
              </Button>
            </Form.Item>
          </Col>
        )}
      </Row>
      {profileTemplate?.[formFieldName]?.[index]?.switchCustom && (
        <div className="custom-rule-border mrg-btm-10">
          <Row className="mrg-btm-10">
            <Col span={10}>
              <Form.Item
                name={[field.name, 'actionWhenPending']}
                fieldKey={[field.fieldKey, 'actionWhenPending']}
                label={translate(
                  `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.actionWhenPending.label`
                )}
                rules={[{ required: rulesRequiredActionWhenPending() }]}
                className={
                  profileTemplate?.[formFieldName]?.[index]?.actionWhenPending ||
                  isProductRule?.actionWhenPending
                }
              >
                <Select
                  placeholder={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.actionWhenInvalid.select.placeholder`
                  )}
                  disabled={disabled}
                  dropdownClassName="validations-action-select-dropdown"
                  allowClear
                >
                  {actionWhenInvalidOptions(
                    profileTemplate?.[formFieldName]?.[index]?.rule?.key
                  ).map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.title}
                    </Option>
                  ))}
                  <Option key="PROCESSING" value="PROCESSING">
                    {translate(
                      `${I18N_BASE_PATH}.validationsFormSelector.stayProcessing`
                    )}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            {formFieldName !== 'profileRules' && hasCustomStatus && (
              <Col span={10}>
                <Form.Item
                  name={[field.name, 'customActionWhenInvalid']}
                  fieldKey={[field.fieldKey, 'customActionWhenInvalid']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.customActionWhenInvalid.label`
                  )}
                  rules={[{ required: false }]}
                  className={
                    profileTemplate?.[formFieldName]?.[index]?.customActionWhenInvalid
                  }
                >
                  <Input style={{ textTransform: 'uppercase' }} disabled={disabled} />
                </Form.Item>
              </Col>
            )}
          </Row>
          {profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
            'minimum_company_lifetime' && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'months']}
                  fieldKey={[field.fieldKey, 'months']}
                  label={translate(
                    `pages.private.profileModels.components.complianceRules.validationsFormSelector.validationsFormSelector.form.formItems.minimumCompanyLifetime.label`
                  )}
                  rules={[{ required: true }]}
                  className="width-15"
                >
                  <InputNumber disabled={disabled} />
                </Form.Item>
              </Row>
            </>
          )}
          {profileTemplate?.[formFieldName]?.[index]?.rule?.key === 'qsa_consistent' && (
            <>
              <Tooltip
                placement="topLeft"
                title={translate(
                  `pages.private.profileModels.components.complianceRules.validationsFormSelector.validationsFormSelector.form.formItems.qsaConsistent.tooltip`
                )}
              >
                <span>
                  {translate(
                    `pages.private.profileModels.components.complianceRules.validationsFormSelector.validationsFormSelector.form.formItems.qsaConsistent.title`
                  )}
                </span>
                <Form.Item
                  name={[field.name, 'onlyQsaCompatible']}
                  fieldKey={[field.fieldKey, 'onlyQsaCompatible']}
                  valuePropName="checked"
                  className="mrg-btm-15"
                >
                  <Switch disabled={disabled} className="switch-compliance-rules" />
                </Form.Item>
              </Tooltip>
            </>
          )}
          {profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
            'has_processes_keywords' && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'searchAllFields']}
                  fieldKey={[field.fieldKey, 'searchAllFields']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.listKeywords.label`
                  )}
                  rules={[{ required: true }]}
                  className="width-100"
                >
                  <Select disabled={disabled} mode="tags" className="width-100" />
                </Form.Item>
              </Row>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'archived']}
                  fieldKey={[field.fieldKey, 'archived']}
                  rules={[{ required: false }]}
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox disabled={disabled} defaultChecked={false}>
                    {translate(
                      `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.archived.label`
                    )}
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'parts']}
                  fieldKey={[field.fieldKey, 'parts']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.parts.label`
                  )}
                  rules={[{ required: true }]}
                  className="width-100"
                >
                  <Select mode="tags" disabled={disabled} className="width-100">
                    {parts.map((part) => (
                      <Option key={part} value={part}>
                        {part}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
            </>
          )}
          {[
            'ocr_equals_parameters_data',
            'ocr_equals_receita',
            'parameters_equals_receita',
          ].includes(profileTemplate?.[formFieldName]?.[index]?.rule?.key) && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'parametersToCompare']}
                  fieldKey={[field.fieldKey, 'parametersToCompare']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.parametersToCompare.label`
                  )}
                  rules={[{ required: true }]}
                  className="width-100"
                >
                  <Select mode="multiple" disabled={disabled} className="width-100">
                    {possibleParametersToCompare.map((field) => (
                      <Option key={field} value={field}>
                        {translate(
                          `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.parametersToCompare.fieldName.${field}`
                        )}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
            </>
          )}
          {profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
            'invalid_range_ages' && (
            <Form.List
              name={[field.name, 'invalidRangeAgesList']}
              fieldKey={[field.fieldKey, 'invalidRangeAgesList']}
              min={1}
              rules={[{ required: true }]}
            >
              {(fields, { add, remove }) => (
                <>
                  <p
                    style={{
                      'font-size': '13px',
                      color: '#7f7f7f',
                      'font-weight': '300',
                    }}
                  >
                    {translate(
                      `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.invalidRangeAges.title`
                    )}
                  </p>
                  {fields.length === 0 ? (
                    <p className="mrg-top-15 mrg-btm-15" style={{ color: '#e21b45' }}>
                      <i className="caf-ic_alert-triangle mrg-right-5" />
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.invalidRangeAges.validation`
                      )}
                    </p>
                  ) : (
                    <Row gutter={[24, 8]}>
                      {fields.map((field) => (
                        <Col span={8}>
                          <Card>
                            <div
                              className="flex align-center space-between"
                              style={{ gap: 10 }}
                            >
                              <div className="flex" style={{ gap: 10 }}>
                                <Form.Item
                                  label={translate(
                                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.invalidRangeAges.min.label`
                                  )}
                                  name={[field.name, 'min']}
                                  fieldKey={[field.fieldKey, 'min']}
                                  tooltip={translate(
                                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.invalidRangeAges.min.tooltip`
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={disabled}
                                    showSearch
                                    placeholder={translate(
                                      `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.invalidRangeAges.placeholder`
                                    )}
                                  >
                                    {ages.map((age) => (
                                      <Option key={age} value={age}>
                                        {age}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  label={translate(
                                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.invalidRangeAges.max.label`
                                  )}
                                  name={[field.name, 'max']}
                                  fieldKey={[field.fieldKey, 'max']}
                                  tooltip={translate(
                                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.invalidRangeAges.max.tooltip`
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={disabled}
                                    showSearch
                                    placeholder={translate(
                                      `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.invalidRangeAges.placeholder`
                                    )}
                                  >
                                    {ages.map((age) => (
                                      <Option key={age} value={age}>
                                        {age}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                              <div>
                                <Button
                                  type="danger"
                                  className="remove-button mrg-top-20 mrg-btn-20"
                                  onClick={() => remove(field.name)}
                                  disabled={disabled}
                                >
                                  <i className="caf-ic_trash" />
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  )}
                  <Form.Item>
                    <Button
                      type="link"
                      onClick={() => add()}
                      className="mrg-top-15 mrg-btm-15"
                      disabled={disabled}
                    >
                      <i className="caf-ic_plus font-size-14 pdd-right-5" />
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.invalidRangeAges.addButton`
                      )}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
          {['score_check_below', 'credit_score_below'].includes(
            profileTemplate?.[formFieldName]?.[index]?.rule?.key
          ) && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'belowScore']}
                  fieldKey={[field.fieldKey, 'belowScore']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.scoreCheckBelow.label`
                  )}
                  rules={[{ required: true }]}
                  className="width-20"
                >
                  <InputNumber disabled={disabled} max={9999} min={0} />
                </Form.Item>
              </Row>
            </>
          )}
          {['score_check_over', 'credit_score_over'].includes(
            profileTemplate?.[formFieldName]?.[index]?.rule?.key
          ) && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'overScore']}
                  fieldKey={[field.fieldKey, 'overScore']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.scoreCheckOver.label`
                  )}
                  rules={[{ required: true }]}
                  className="width-20"
                >
                  <InputNumber disabled={disabled} max={9999} min={0} />
                </Form.Item>
              </Row>
            </>
          )}
          {['score_check_between', 'credit_score_between'].includes(
            profileTemplate?.[formFieldName]?.[index]?.rule?.key
          ) && (
            <div>
              <span>
                {translate(
                  `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.scoreCheckBetween.label`
                )}
              </span>
              <div className="flex mrg-top-20 width-50">
                <Form.Item
                  name={[field.name, 'minScore']}
                  fieldKey={[field.fieldKey, 'minScore']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.scoreCheckBetween.min`
                  )}
                  rules={[{ required: true }]}
                  className="width-20 mrg-right-20"
                >
                  <InputNumber
                    disabled={disabled}
                    max={9999}
                    min={0}
                    className="width-10"
                  />
                </Form.Item>
                <Form.Item
                  name={[field.name, 'maxScore']}
                  fieldKey={[field.fieldKey, 'maxScore']}
                  rules={[{ required: true }]}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.scoreCheckBetween.max`
                  )}
                  className="width-20"
                >
                  <InputNumber
                    disabled={disabled}
                    max={9999}
                    min={0}
                    className="width-10"
                  />
                </Form.Item>
              </div>
            </div>
          )}
          {['has_no_pep_department', 'has_no_sanctions'].includes(
            profileTemplate?.[formFieldName]?.[index]?.rule?.key
          ) && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[
                    field.name,
                    profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
                    'has_no_pep_department'
                      ? 'departmentsType'
                      : 'sourcesType',
                  ]}
                  fieldKey={[
                    field.fieldKey,
                    profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
                    'has_no_pep_department'
                      ? 'departmentsType'
                      : 'sourcesType',
                  ]}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.hasNoPepDepartment.blacklistOrWhitelist`
                  )}
                  rules={[{ required: true }]}
                  className="width-100"
                >
                  <Radio.Group disabled={disabled}>
                    <Radio value="ALLOW">
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.hasNoPepDepartment.allow`
                      )}
                    </Radio>
                    <Radio value="DENY">
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.hasNoPepDepartment.block`
                      )}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[
                    field.name,
                    profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
                    'has_no_pep_department'
                      ? 'departments'
                      : 'sources',
                  ]}
                  fieldKey={[
                    field.fieldKey,
                    profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
                    'has_no_pep_department'
                      ? 'departments'
                      : 'sources',
                  ]}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.hasNoPepDepartment.label`
                  )}
                  rules={[{ required: true }]}
                  className="width-100"
                >
                  {profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
                  'has_no_pep_department' ? (
                    <Select disabled={disabled} mode="tags" className="width-100" />
                  ) : (
                    <Select
                      disabled={disabled}
                      mode="tags"
                      className="width-100"
                      allowClear
                    >
                      {sources.map((source) => (
                        <Option key={source} value={source}>
                          {source}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Row>
            </>
          )}
          {profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
            'has_at_least_one_cnae' && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'cnaesType']}
                  fieldKey={[field.fieldKey, 'cnaesType']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cnae.blacklistOrWhitelist`
                  )}
                  rules={[{ required: false }]}
                  className="width-100"
                >
                  <Radio.Group disabled={disabled}>
                    <Radio value="ALLOW">
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cnae.allow`
                      )}
                    </Radio>
                    <Radio value="DENY">
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cnae.block`
                      )}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name={[field.name, 'cnaes']}
                  fieldKey={[field.fieldKey, 'cnaes']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cnae.listOfCnaes`
                  )}
                  rules={[{ required: false }]}
                  className="width-100"
                >
                  <Select disabled={disabled} mode="tags" className="width-100" />
                </Form.Item>
              </Row>
            </>
          )}
          {profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
            'has_at_least_one_legal_nature' && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'legalNatureType']}
                  fieldKey={[field.fieldKey, 'legalNatureType']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.legalNature.blacklistOrWhitelist`
                  )}
                  rules={[{ required: false }]}
                  className="width-100"
                >
                  <Radio.Group disabled={disabled}>
                    <Radio value="ALLOW">
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.legalNature.allow`
                      )}
                    </Radio>
                    <Radio value="DENY">
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.legalNature.block`
                      )}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name={[field.name, 'legalNature']}
                  fieldKey={[field.fieldKey, 'legalNature']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.legalNature.legalNatureList`
                  )}
                  rules={[{ required: false }]}
                  className="width-100"
                >
                  <Select disabled={disabled} mode="tags" className="width-100" />
                </Form.Item>
              </Row>
            </>
          )}
          {profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
            'ocr_fields_not_empty' && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'requiredFields']}
                  fieldKey={[field.fieldKey, 'requiredFields']}
                  label={translate(`${I18N_REQUIRED_FIELDS}.label`)}
                  rules={[{ required: false }]}
                  className="width-100"
                >
                  <Select disabled={disabled} mode="tags" className="width-100">
                    <Option value="cpf">
                      {translate(`${I18N_REQUIRED_FIELDS}.option.cpf`)}
                    </Option>
                    <Option value="name">
                      {translate(`${I18N_REQUIRED_FIELDS}.option.name`)}
                    </Option>
                    <Option value="rg">
                      {translate(`${I18N_REQUIRED_FIELDS}.option.rg`)}
                    </Option>
                    <Option value="motherName">
                      {translate(`${I18N_REQUIRED_FIELDS}.option.motherName`)}
                    </Option>
                    <Option value="birthDate">
                      {translate(`${I18N_REQUIRED_FIELDS}.option.birthDate`)}
                    </Option>
                    <Option value="issueDate">
                      {translate(`${I18N_REQUIRED_FIELDS}.option.issueDate`)}
                    </Option>
                    <Option value="issueState">
                      {translate(`${I18N_REQUIRED_FIELDS}.option.issueState`)}
                    </Option>
                  </Select>
                </Form.Item>
              </Row>
            </>
          )}
          {profileTemplate?.[formFieldName]?.[index]?.rule?.key ===
            'cnh_has_valid_date' && (
            <>
              <Form.Item
                name={[field.name, 'timeUnit']}
                fieldKey={[field.fieldKey, 'timeUnit']}
                label={translate(
                  `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cnhValidDate.unit`
                )}
                rules={[{ required: false }]}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value="days">
                    {translate(
                      `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cnhValidDate.days`
                    )}
                  </Radio>
                  <Radio value="months">
                    {translate(
                      `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cnhValidDate.months`
                    )}
                  </Radio>
                  <Radio value="years">
                    {translate(
                      `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cnhValidDate.years`
                    )}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={[field.name, 'limit']}
                fieldKey={[field.fieldKey, 'limit']}
                label={translate(
                  `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cnhValidDate.number`
                )}
                rules={[{ required: false }]}
                className="mrg-right-30"
              >
                <Input disabled={disabled} style={{ width: 100 }} />
              </Form.Item>
            </>
          )}
          {profileTemplate?.[formFieldName]?.[index]?.rule?.key === 'cpf_error_code' && (
            <>
              <Row className="no-mrg no-pdd">
                <Form.Item
                  name={[field.name, 'cpfType']}
                  fieldKey={[field.fieldKey, 'cpfType']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cpfErrorCode.blacklistOrWhitelist`
                  )}
                  rules={[{ required: false }]}
                  className="width-100"
                >
                  <Radio.Group disabled={disabled}>
                    <Radio value="ALLOW">
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cpfErrorCode.allow`
                      )}
                    </Radio>
                    <Radio value="DENY">
                      {translate(
                        `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cpfErrorCode.block`
                      )}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name={[field.name, 'cpf']}
                  fieldKey={[field.fieldKey, 'cpf']}
                  label={translate(
                    `${I18N_BASE_PATH}.validationsFormSelector.form.formItems.cpfErrorCode.cpfList`
                  )}
                  rules={[{ required: false }]}
                  className="width-100"
                >
                  <Select disabled={disabled} mode="tags" className="width-100" />
                </Form.Item>
              </Row>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ComplianceRule;
