/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Breadcrumb, message, Row, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import _ from 'lodash';

import { useFetch } from 'services/hooks';
import { useProfileTemplate } from 'hooks/profileTemplate';

import Button from 'components/Button';

import PermissionWrapper from 'routes/PermissionWrapper';
import SwitchRenderer from '../../../ProfileModels/components/ProfileModelList/components/SwitchRenderer';
import useConfirmAction from '../../../ProfileModels/components/ConfirmAction';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelUpdate.components.subheader.index';

const Subheader = ({
  formRef,
  loading: initialLoading,
  disabled,
  setDisabled,
  fetch,
  type,
}) => {
  const { t: translate } = useTranslation();

  const { profileTemplateId } = useParams();
  const { post, put, loading: loadingSave } = useFetch();
  const {
    profileIsActive,
    profileTemplate,
    product,
    serviceIsOnProduct,
  } = useProfileTemplate();

  const { ConfirmActionModal, openModal } = useConfirmAction({
    refreshListRef: null,
    type: 'profile-template',
    refreshStatus: () => fetch(),
  });
  const history = useHistory();

  const standardBreadcrumbs = useMemo(
    () => (
      <Breadcrumb separator="/">
        <Breadcrumb.Item className="breadcrumbs-color">
          <Link to="/profile-templates/profile-models/?active=true" className="gx-link">
            {translate(`${I18N_BASE_PATH}.standardBreadcrumbs.profileTemplates`)}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumbs-color">
          {profileTemplateId
            ? `${translate(
                `${I18N_BASE_PATH}.standardBreadcrumbs.text.hasProfileTemplateId.before.${
                  disabled ? 'read' : 'edit'
                }`
              )} ${translate(
                `${I18N_BASE_PATH}.standardBreadcrumbs.text.hasProfileTemplateId.inside`
              )}`
            : translate(
                `${I18N_BASE_PATH}.standardBreadcrumbs.text.hasNotProfileTemplateId.before`
              )}{' '}
          {translate(
            `${I18N_BASE_PATH}.standardBreadcrumbs.text.hasNotProfileTemplateId.inside`
          )}
        </Breadcrumb.Item>
      </Breadcrumb>
    ),
    [profileTemplateId, disabled, translate]
  );
  const save = useCallback(
    async (payload) => {
      try {
        if (profileTemplateId) {
          await put({
            url: `/profile-templates/${profileTemplateId}`,
            payload,
          });
          message.success(
            translate(
              `${I18N_BASE_PATH}.onFinishHandler.messages.success.hasProfileTemplateId`
            )
          );
        } else {
          await post({
            url: '/profile-templates',
            payload,
          });
          message.success(
            translate(
              `${I18N_BASE_PATH}.onFinishHandler.messages.success.hasNotProfileTemplateId`
            )
          );
        }
        history.push(`/profile-templates/profile-models?active=true`);
      } catch (error) {
        message.error(
          translate(
            `${I18N_BASE_PATH}.onFinishHandler.messages.error.hasNotProfileTemplateId`
          )
        );
      }
    },
    [put, post]
  );

  const showConfirmModal = useCallback((payload) => {
    confirm({
      title: translate(`${I18N_BASE_PATH}.modalConfirm.title`),
      icon: <ExclamationCircleOutlined />,
      content: translate(`${I18N_BASE_PATH}.modalConfirm.subtitle`),
      onOk() {
        save(payload);
      },
    });
  });

  const buildExtraSettingsValidation = useCallback(
    (
      cnaes,
      cnaesType,
      legalNature,
      legalNatureType,
      requiredFields,
      rule,
      limit,
      timeUnit,
      cpfType,
      cpf,
      invalidRangeAgesList,
      parametersToCompare,
      months,
      _belowScore,
      _overScore,
      _minScore,
      _maxScore,
      onlyQsaCompatible,
      departments,
      departmentsType,
      sources,
      sourcesType
    ) => {
      if (legalNatureType && rule?.value === 'has_at_least_one_legal_nature')
        return { type: legalNatureType, list: legalNature };
      if (cnaesType && rule?.value === 'has_at_least_one_cnae')
        return { type: cnaesType, list: cnaes };
      if (requiredFields && rule?.value === 'ocr_fields_not_empty')
        return { requiredFields };
      if (limit && timeUnit && rule?.value === 'cnh_has_valid_date')
        return { limit: parseInt(limit, 10), timeUnit };
      if (cpfType && cpf && rule?.value === 'cpf_error_code')
        return { type: cpfType, list: cpf };
      if (rule?.value === 'invalid_range_ages')
        return { invalidRangeAges: invalidRangeAgesList };
      if (
        [
          'ocr_equals_parameters_data',
          'ocr_equals_receita',
          'parameters_equals_receita',
        ].includes(rule?.value)
      )
        return { parametersToCompare };
      if (rule?.value === 'minimum_company_lifetime') return { months };
      if (['score_check_below', 'credit_score_below'].includes(rule?.value)) {
        const belowScore = parseInt(_belowScore, 10);
        return { belowScore };
      }
      if (['score_check_over', 'credit_score_over'].includes(rule?.value)) {
        const overScore = parseInt(_overScore, 10);
        return { overScore };
      }
      if (['score_check_between', 'credit_score_between'].includes(rule?.value)) {
        const minScore = parseInt(_minScore, 10);
        const maxScore = parseInt(_maxScore, 10);
        return {
          minScore,
          maxScore,
        };
      }
      if (rule?.value === 'qsa_consistent') return { onlyQsaCompatible };
      if (departmentsType && rule?.value === 'has_no_pep_department') {
        return { list: departments, type: departmentsType };
      }
      if (sourcesType && rule?.value === 'has_no_sanctions') {
        return { list: sources, type: sourcesType };
      }
      return undefined;
    },
    []
  );
  const buildPayload = useCallback(
    (data) => {
      const {
        rule,
        actionWhenInvalid,
        actionWhenPending,
        customActionWhenInvalid,
        switchCustom,
        cnaesType,
        cnaes,
        legalNatureType,
        legalNature,
        requiredFields,
        limit,
        timeUnit,
        cpfType,
        cpf,
        invalidRangeAgesList,
        parametersToCompare,
        months,
        belowScore,
        overScore,
        minScore,
        maxScore,
        onlyQsaCompatible,
        departments,
        departmentsType,
        sources,
        sourcesType,
      } = data;

      let switchCustomOverride = switchCustom;

      if (
        [
          'score_check_below',
          'score_check_over',
          'score_check_between',
          'credit_score_below',
          'credit_score_over',
          'credit_score_between',
          'parameters_equals_receita',
          'ocr_equals_parameters_data',
          'ocr_equals_receita',
          'has_processes_keywords',
        ].includes(rule?.value || rule)
      ) {
        switchCustomOverride = true;
      }

      return {
        rule: rule?.value || rule,
        actionWhenInvalid,
        actionWhenPending: switchCustomOverride
          ? actionWhenPending || undefined
          : undefined,
        customActionWhenInvalid: switchCustomOverride
          ? customActionWhenInvalid || undefined
          : undefined,
        extraSettings: switchCustomOverride
          ? buildExtraSettingsValidation(
              cnaes,
              cnaesType,
              legalNature,
              legalNatureType,
              requiredFields,
              rule,
              limit,
              timeUnit,
              cpfType,
              cpf,
              invalidRangeAgesList,
              parametersToCompare,
              months,
              belowScore,
              overScore,
              minScore,
              maxScore,
              onlyQsaCompatible,
              departments,
              departmentsType,
              sources,
              sourcesType
            )
          : undefined,
      };
    },
    [buildExtraSettingsValidation]
  );

  const buildExtraSettings = useCallback((formValue) => {
    const processesKeywordsObject = formValue?.validations?.find(
      ({ rule }) => rule?.key === 'has_processes_keywords'
    );
    return {
      processesWithKeywords:
        processesKeywordsObject && processesKeywordsObject?.switchCustom
          ? {
              searchAllFields: processesKeywordsObject?.searchAllFields,
              archived: processesKeywordsObject?.archived,
              parts: processesKeywordsObject?.parts,
            }
          : undefined,
      alwaysManualReview: formValue.alwaysManualReview,
    };
  }, []);

  const onFinishHandler = useCallback(async () => {
    if (!formRef) return;

    try {
      await formRef.validateFields();
    } catch (error) {
      const { errorFields } = error;

      if (errorFields?.length > 0) {
        formRef.scrollToField(errorFields[0].name);
      }

      message.warning(translate(`${I18N_BASE_PATH}.onFinishHandler.messages.warning`));

      return;
    }

    const formValues = formRef.getFieldsValue();

    const profileRules = formValues?.profileRules
      ?.map((rule) => buildPayload(rule))
      ?.filter((pr) => !_.isEmpty(pr) && pr?.rule && pr?.actionWhenInvalid);

    const backgroundChecking = {
      services: Object.keys(formValues?.backgroundCheckingServices || {})
        ?.filter((source) => formValues?.backgroundCheckingServices[source])
        ?.map((source) => ({
          source,
          frequency: formValues?.backgroundCheckingServices[source].frequency,
          onProfileCreation:
            formValues?.backgroundCheckingServices[source].onProfileCreation,
        })),
      validations: formValues?.validations
        ?.filter(({ category }) => category !== 'onboarding')
        ?.map((rule) => buildPayload(rule)),
    };

    const selectedOnboardingServices =
      Object.keys(formValues?.onboardingServices || {})
        ?.filter((source) => formValues?.onboardingServices[source])
        ?.map((source) => ({ source })) || [];

    const onbServicesNotOnProduct = selectedOnboardingServices?.filter(
      (s) => s?.source && !serviceIsOnProduct(s.source)
    );

    const onboarding = {
      services: product?.source ? onbServicesNotOnProduct : selectedOnboardingServices,
      validations: formValues?.validations
        ?.filter(({ category }) => category === 'onboarding')
        ?.map((rule) => buildPayload(rule)),
    };

    const arrayForValidation = onboarding.services.concat(backgroundChecking.services);
    if (!arrayForValidation.length && !product) {
      message.error(
        translate(
          `${I18N_BASE_PATH}.onFinishHandler.messages.error.hasNotArrayForValidation`
        )
      );
      return;
    }

    const payload = {
      type,
      name: formValues?.name?.trim(),
      webhook: formValues?.webhook?.trim(),
      profileWebhook: formValues?.profileWebhook?.trim(),
      qsaProfileTemplateId:
        formValues?.qsaSwitch || formValues?.qsaProfileTemplateId
          ? formValues?.qsaProfileTemplateId
          : undefined,
      backgroundChecking,
      onboarding,
      onboardingTemplateId: formValues?.onboardingTemplateId,
      extraSettings: buildExtraSettings(formValues),
    };

    if ((type === 'PJ' && profileTemplate?.qsaSwitch) || type === 'PF') {
      payload.profileRules = profileRules || [];
    }
    if (
      (!profileTemplate?.validations || profileTemplate?.validations.length === 0) &&
      (!profileTemplate?.profileRules || !profileTemplate?.profileRules.length)
    ) {
      showConfirmModal(payload);
      return;
    }

    save(payload);
  }, [
    formRef,
    product,
    type,
    buildExtraSettings,
    profileTemplate,
    translate,
    buildPayload,
    serviceIsOnProduct,
    profileTemplateId,
    history,
    put,
    post,
  ]);
  return (
    <section id="profile-model-subheader">
      {ConfirmActionModal}
      <div className="gx-header-horizontal-top">
        <div>
          <div className="flex-row gx-align-items-end gx-justify-content-between">
            <div className="flex-1 pdd-right-10 ">
              {standardBreadcrumbs}
              <div className="flex-row">
                <h5 className="profile-model-name max-lines-2">
                  {profileTemplateId
                    ? disabled
                      ? profileTemplate?.name
                      : translate(
                          `${I18N_BASE_PATH}.subheader.title.hasProfileTemplateId.edit`
                        )
                    : `${translate(
                        `${I18N_BASE_PATH}.subheader.title.hasNotProfileTemplateId.before`
                      )} ${translate(
                        `${I18N_BASE_PATH}.subheader.title.hasNotProfileTemplateId.inside`
                      )}`}
                </h5>
                <div className="flex center mrg-left-10">
                  {!initialLoading && (
                    <Tag className="tag-type">{profileTemplate?.type}</Tag>
                  )}
                </div>
              </div>
            </div>
            <Row className="mrg-row-btm">
              {profileTemplateId && disabled ? (
                <div className="center-end container-switch">
                  <div className="content-switch">
                    <SwitchRenderer
                      data={{
                        ...profileTemplate,
                        active: profileIsActive,
                        _id: profileTemplateId,
                      }}
                      openModal={openModal}
                    />
                  </div>
                </div>
              ) : (
                <Button
                  disabled={loadingSave}
                  onClick={() => {
                    setDisabled(true);
                    fetch();
                  }}
                  type="default"
                  className="btn-recurrence-cancel pdd-horizon-35"
                >
                  <span>{translate(`${I18N_BASE_PATH}.subheader.button.cancel`)}</span>
                </Button>
              )}

              {profileTemplateId && (
                <PermissionWrapper requiredPermissions={['profile-templates:update']}>
                  <Button
                    disabled={initialLoading || loadingSave || !profileIsActive}
                    type="primary"
                    className="pdd-left-40 pdd-right-40"
                    onClick={() => {
                      if (disabled) {
                        setDisabled((oldState) => !oldState);
                      } else {
                        onFinishHandler();
                      }
                    }}
                  >
                    <span>
                      {disabled
                        ? translate(`${I18N_BASE_PATH}.subheader.button.editOrSave.edit`)
                        : `${translate(
                            `${I18N_BASE_PATH}.subheader.button.editOrSave.save.${
                              !loadingSave ? 'default' : 'loading'
                            }`
                          )}`}
                    </span>
                  </Button>
                </PermissionWrapper>
              )}

              {!profileTemplateId && (
                <PermissionWrapper requiredPermissions={['profile-templates:create']}>
                  <Button
                    disabled={initialLoading || loadingSave}
                    type="primary"
                    className="pdd-left-40 pdd-right-40"
                    onClick={onFinishHandler}
                  >
                    <span>
                      {translate(
                        `${I18N_BASE_PATH}subheader.button.hasNotProfileTemplateId.${
                          !loadingSave ? 'default' : 'loading'
                        }`
                      )}
                    </span>
                  </Button>
                </PermissionWrapper>
              )}
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

Subheader.propTypes = {
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
};

export default Subheader;
